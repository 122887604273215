import { ButtonHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { borderRadius, boxShadow, colors, spacing } from 'theme';
import LoaderWithDots from 'components/icons/LoaderWithDots';
import { isUrlAbsolute } from 'helpers/link';
import { functionalColors } from 'theme/colors';
import Icon from '../icon/Icon';
import { IconName, isValidIconName } from '../icon/icon-map';
import withProps from '../utils/withProps';
import { UnstyledButton } from './UnstyledButton';
export const buttonVariants = (['primary', 'primary-outlined', 'primary-ghost', 'primary-inverted', 'primary-inverted-outlined', 'primary-inverted-ghost', 'secondary', 'secondary-outlined', 'secondary-ghost', 'destructive', 'success', 'warning', 'info'] as const);
export type ButtonVariant = typeof buttonVariants[number];
interface IButtonBase {
  variant: ButtonVariant;
}
export const ButtonBase = styled(UnstyledButton)<IButtonBase>({
  height: spacing.xxl,
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'nowrap',
  gap: spacing.xs,
  border: '2px solid transparent',
  borderRadius: borderRadius.md,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '130%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  userSelect: 'none',
  transition: 'background-color 100ms, color 100ms, border-color 100ms',
  backgroundColor: 'transparent',
  color: 'inherit',
  borderColor: 'transparent',
  '&:focus-visible': {
    outline: 'none',
    boxShadow: boxShadow.focusRing
  },
  '&[aria-disabled="true"]': {
    cursor: 'not-allowed'
  }
}, ({
  variant
}) => ({
  primary: {
    padding: `0px ${spacing.md}`,
    backgroundColor: colors.surface.primary,
    color: colors.text.white,
    '&:hover': {
      backgroundColor: colors.hover.primary,
      color: colors.text.white
    },
    '&[aria-disabled="true"]': {
      backgroundColor: colors.surface.disabledDark,
      color: colors.text.disabledLight
    }
  },
  'primary-outlined': {
    padding: `0px ${spacing.md}`,
    backgroundColor: 'transparent',
    color: colors.text.primary,
    borderColor: colors.surface.primary,
    '&:hover': {
      color: colors.hover.primary,
      borderColor: colors.hover.primary
    },
    '&[aria-disabled="true"]': {
      color: colors.text.disabledDark,
      borderColor: colors.surface.disabledDark
    }
  },
  'primary-ghost': {
    borderWidth: 0,
    backgroundColor: 'transparent',
    color: colors.text.primary,
    '&:hover': {
      color: colors.hover.primary
    },
    '&[aria-disabled="true"]': {
      color: colors.text.disabledDark
    }
  },
  'primary-inverted': {
    padding: `0px ${spacing.md}`,
    backgroundColor: colors.surface.white,
    color: colors.text.primary,
    '&:hover': {
      backgroundColor: colors.hover.secondary,
      color: colors.text.primary
    },
    '&[aria-disabled="true"]': {
      backgroundColor: colors.surface.disabledDark,
      color: colors.text.disabledDark
    }
  },
  'primary-inverted-outlined': {
    padding: `0px ${spacing.md}`,
    backgroundColor: 'transparent',
    color: colors.text.white,
    borderColor: colors.surface.white,
    '&:hover': {
      color: colors.hover.secondary,
      borderColor: colors.hover.secondary
    },
    '&[aria-disabled="true"]': {
      color: colors.text.disabledDark,
      borderColor: colors.surface.disabledDark
    }
  },
  'primary-inverted-ghost': {
    borderWidth: 0,
    backgroundColor: 'transparent',
    color: colors.text.white,
    '&:hover': {
      color: colors.hover.secondary
    },
    '&[aria-disabled="true"]': {
      color: colors.text.disabledDark
    }
  },
  secondary: {
    padding: `0px ${spacing.md}`,
    backgroundColor: colors.surface.secondary,
    color: colors.text.white,
    '&:hover': {
      backgroundColor: colors.hover.primary,
      color: colors.text.white
    },
    '&[aria-disabled="true"]': {
      backgroundColor: colors.surface.disabledDark,
      color: colors.text.disabledLight
    }
  },
  'secondary-outlined': {
    padding: `0px ${spacing.md}`,
    backgroundColor: 'transparent',
    color: colors.surface.secondary,
    borderColor: colors.surface.secondary,
    '&:hover': {
      color: colors.hover.primary,
      borderColor: colors.hover.primary
    },
    '&[aria-disabled="true"]': {
      color: colors.text.disabledDark,
      borderColor: colors.surface.disabledDark
    }
  },
  'secondary-ghost': {
    borderWidth: 0,
    backgroundColor: 'transparent',
    color: colors.surface.secondary,
    '&:hover': {
      color: colors.hover.primary
    },
    '&[aria-disabled="true"]': {
      color: colors.text.disabledDark
    }
  },
  destructive: {
    padding: `0px ${spacing.md}`,
    backgroundColor: colors.surface.error,
    color: colors.text.white,
    '&:hover': {
      backgroundColor: functionalColors.error[600],
      color: colors.text.white
    },
    '&[aria-disabled="true"]': {
      backgroundColor: colors.surface.disabledDark,
      color: colors.text.disabledLight
    }
  },
  success: {
    padding: `0px ${spacing.md}`,
    backgroundColor: colors.surface.success,
    color: colors.text.white,
    '&:hover': {
      backgroundColor: functionalColors.success[600],
      color: colors.text.white
    },
    '&[aria-disabled="true"]': {
      backgroundColor: colors.surface.disabledDark,
      color: colors.text.disabledLight
    }
  },
  warning: {
    padding: `0px ${spacing.md}`,
    backgroundColor: colors.surface.warning,
    color: colors.text.white,
    '&:hover': {
      backgroundColor: functionalColors.warning[600],
      color: colors.text.white
    },
    '&[aria-disabled="true"]': {
      backgroundColor: colors.surface.disabledDark,
      color: colors.text.disabledLight
    }
  },
  info: {
    padding: `0px ${spacing.md}`,
    backgroundColor: colors.surface.info,
    color: colors.text.white,
    '&:hover': {
      backgroundColor: functionalColors.info[600],
      color: colors.text.white
    },
    '&[aria-disabled="true"]': {
      backgroundColor: colors.surface.disabledDark,
      color: colors.text.disabledLight
    }
  }
})[variant]);
interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariant;
  iconLeft?: IconName | JSX.Element;
  iconRight?: IconName | JSX.Element;
  loading?: boolean;
  /** If provided, the Button renders as <a> tag with corresponding styles. */
  href?: string;
  'data-cy-element-type'?: string;
  'data-cy-element-id'?: string;
  'data-cy-link'?: string;
}

/**
 * Primitive UI component for rendering buttons. Extends HTML button and applies styles from button variants defined in Figma.
 *
 * For rendering icon buttons without text, use IconButton instead. To group buttons together and render them as one multi-segment button, use SplitButton.
 *
 * For unique non-standard buttons, please consider using UnstyledButton instead of extending this with a one-off variant (if it's not defined in Figma).
 *
 * [Figma definition](https://www.figma.com/design/2fEScGXkSAxlZaLBCm4ki2/%F0%9F%92%A0-Official-Design-Library?node-id=192-31734&t=vdo5QWOiOwa9Ukgj-4)
 *
 * @example
 *
 * ```tsx
 * // Default primary filled button.
 * <Button onClick={() => handleSave()}>Save</Button>
 *
 * // Default primary filled button that acts as a link. `disabled` prop disables the link when passed.
 * <Button href="/feature-flags">Save</Button>
 *
 * // Default primary button with an "Add circle" icon on the left.
 * <Button iconLeft="Add circle">Create...</Button>
 *
 * // Default primary button with a custom left icon.
 * <Button iconLeft={<GraphicIcon name="Logo.Slack" size={20} />}>Connect to Slack</Button>
 *
 * // Secondary outlined button with an "Edit" icon on the right.
 * <Button variant="secondary-outlined" iconRight="Edit">Edit</Button>
 *
 * // Destructive button.
 * <Button variant="destructive">Delete</Button>
 *
 * // Grouped buttons with a primary button and a separate icon button on the right.
 * <SplitButton>
 *   <Button variant="primary">Save</Button>
 *   <IconButton name="Caret.Down" variant="primary" />
 * </SplitButton>
 * ```
 */
export default function Button({
  variant = 'primary',
  iconLeft,
  iconRight,
  href,
  children,
  type = 'button',
  disabled,
  loading,
  onClick,
  ...props
}: IButton) {
  const hrefIsAbsoluteURL = href && isUrlAbsolute(href);
  const isDisabled = disabled || loading;
  return <ButtonBase variant={variant} as={href ? withProps(Link, {
    href,
    target: hrefIsAbsoluteURL ? '_blank' : undefined,
    rel: hrefIsAbsoluteURL ? 'noreferrer' : undefined
  }) : undefined} onClick={e => isDisabled ? e.preventDefault() : onClick?.(e)} // Disabling navigation for when a `href` Button is disabled.
  disabled={isDisabled} aria-disabled={isDisabled} type={type} {...props}>
      {loading ? <LoaderWithDots /> : isValidIconName(iconLeft) ? <Icon name={iconLeft} /> : iconLeft}
      {children}
      {isValidIconName(iconRight) ? <Icon name={iconRight} /> : iconRight}
    </ButtonBase>;
}