import { getCookie } from 'cookies-next';
import { NextRequest, NextResponse } from 'next/server';
export const AUTHENTICATION_PATHNAME = '/';
const authPageRedirect = (req: NextRequest) => {
  return NextResponse.redirect(new URL(`${AUTHENTICATION_PATHNAME}?next=${encodeURIComponent(req.nextUrl.pathname)}`, req.nextUrl.origin));
};
export async function middleware(req: NextRequest) {
  const requestedPageIsAuth = req.nextUrl.pathname === AUTHENTICATION_PATHNAME;
  const jwt = getCookie('appSession', {
    req
  });
  if (!jwt && !requestedPageIsAuth) {
    return authPageRedirect(req);
  }
  if (jwt && requestedPageIsAuth) {
    return NextResponse.redirect(new URL('/experiments', req.nextUrl.origin));
  }
  return NextResponse.next();
}
export const config = {
  matcher: [{
    source: '/((?!api|_next/static|_next/image|favicon.*).*)'
  }]
};