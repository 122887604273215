import { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import { init as initCommandBar } from 'commandbar';

import { callGraphQLApiDirectly } from 'helpers/graphql-helper';
import { fromGlobalId } from 'helpers/relay-helper';

import {
  SearchBar_Experiment_Query$data,
  SearchBar_Experiment_Query$variables,
} from '../__generated__/SearchBar_Experiment_Query.graphql';
import { SearchBar_Query$data } from '../__generated__/SearchBar_Query.graphql';
import { SEARCH_BAR_EXPERIMENT_QUERY_RAW } from '../SearchBar';

interface ICommandBarContextItem {
  label: string;
  route: string;
}

export function useCommandBar({ org, userId }: { org: string; userId: string }) {
  const _CommandBar = typeof window !== 'undefined' ? window.CommandBar : null;

  useEffect(() => {
    // if CommandBar doesn't exist and we have an org, run init
    if (_CommandBar || !org) {
      return;
    }
    initCommandBar(org);
  }, [_CommandBar, org]);

  useEffect(() => {
    // once we have both a userId and CommandBar then boot
    if (!userId || !_CommandBar) return;
    _CommandBar.boot(userId, {}, { canOpenEditor: false });
  }, [userId, _CommandBar]);

  return _CommandBar;
}

export function openCommandBar() {
  if (window?.CommandBar?.open) {
    window.CommandBar.open();
  } else {
    console.log(`Command bar is not installed!`);
  }
}

const searchExperimentFunction = async (text: string): Promise<ICommandBarContextItem[]> => {
  let experiments: ICommandBarContextItem[] = [];
  if (!text) return experiments;

  try {
    const results = await callGraphQLApiDirectly<
      SearchBar_Experiment_Query$data,
      SearchBar_Experiment_Query$variables
    >(SEARCH_BAR_EXPERIMENT_QUERY_RAW, {
      input: {
        searchText: text,
      },
    });

    if (!results) return [];
    experiments = results?.searchExperiment?.map(({ name, id }) => ({
      label: name,
      route: `/experiments/${fromGlobalId(id)}`,
    }));
  } catch (err) {
    Sentry.captureException(err);
  }
  return experiments;
};

function addContextWithValue(contextName: string, value: ICommandBarContextItem[]) {
  window.CommandBar.addContext(contextName, value, {
    quickFindOptions: {
      quickFind: true,
    },
  });
}

function addContextWithSearchFunction(
  contextName: string,
  func: (searchText: string) => Promise<ICommandBarContextItem[]>,
) {
  window.CommandBar.addContext(contextName, [], {
    searchOptions: {
      searchFunction: func,
    },
  });
}

export const feedDataToCommandBar = (data: SearchBar_Query$data) => {
  if (window?.CommandBar) {
    const { metrics, assignmentSources, metricEvents, entityDimensionSources } = data;

    const metricContextList: ICommandBarContextItem[] =
      metrics?.map(({ name, id, entity: { name: entityName } }) => ({
        label: `${entityName} | ${name}`,
        route: `/metrics/${fromGlobalId(id)}`,
      })) || [];

    const assignmentContextList: ICommandBarContextItem[] =
      assignmentSources?.map(({ name, id }) => ({
        label: name ?? '',
        route: `/definitions/assignments/${fromGlobalId(id)}`,
      })) || [];

    let factContextList: ICommandBarContextItem[] = [];
    metricEvents?.forEach(({ id, metricEventMeasures }) => {
      factContextList = factContextList.concat(
        metricEventMeasures
          ? metricEventMeasures.map(({ measureName }) => ({
              label: measureName,
              route: `definitions/facts/${fromGlobalId(id)}`,
            }))
          : [],
      );
    });

    let dimensionContextList: ICommandBarContextItem[] = [];
    assignmentSources?.forEach(({ dimensions, id: assignmentId }) => {
      if (dimensions) {
        dimensionContextList = dimensionContextList.concat(
          dimensions.map(({ dimensionName }) => ({
            label: dimensionName,
            route: `/definitions/assignments/${fromGlobalId(assignmentId)}`,
          })),
        );
      }
    });
    entityDimensionSources?.forEach(({ dimensions, id: factId }) => {
      if (dimensions) {
        dimensionContextList = dimensionContextList.concat(
          dimensions.map(({ dimensionName }) => ({
            label: dimensionName,
            route: `/definitions/facts/${fromGlobalId(factId)}`,
          })),
        );
      }
    });

    addContextWithSearchFunction('experiment', searchExperimentFunction);
    addContextWithValue('metric', metricContextList);
    addContextWithValue('assignment', assignmentContextList);
    addContextWithValue('fact', factContextList);
    addContextWithValue('dimension', dimensionContextList);
  }
};
