import { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { EppoDomainRoutes } from 'constants/routes';
import { useRouter } from 'next/router';
import { PreloadedQuery, useMutation, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { Popover, PopoverBody } from 'reactstrap';
import { graphql } from 'relay-runtime';
import ClickOutsideWrapper from 'components/click-outside-wrapper/ClickOutsideWrapper';
import { ProfilePicture } from 'components/profile-picture/ProfilePicture';
import { AddEllipsis } from 'components/style-components/AddEllipsis';
import { Hr } from 'components/style-components/Hr';
import { Suspense } from 'components/Suspense';
import { TooltipWrapper } from 'components/tool-tip/TooltipWrapper';
import Icon from 'components/ui/icon/Icon';
import Tooltip from 'components/ui/tooltip/Tooltip';
import Text from 'components/ui/typography/Text';
import UserProfileSidebar from 'components/user-profile-dropdown/UserProfileSidebar';
import { domSafeElementId } from 'helpers/dom-helper';
import { preventOverflowModifier } from 'helpers/popover-helper';
import { removeCookies } from 'helpers/remove-cookies';
import { useCustomToasts as useToasts } from 'hooks/useCustomToasts';
import { NavCollapsedContext } from 'hooks/useNavCollapsed';
import { useUser } from 'hooks/useUser';
import { colors } from 'theme/colors';
import { CompanyOption, MenuDropdownContainer, MenuDropdownItem, MenuDropdownCompanies, UserProfilePlaceholder, UserProfileDropdownButton, UserProfileDropdownSearch } from './styled';
import { UserProfileDropdown_Query } from './__generated__/UserProfileDropdown_Query.graphql';
import { UserProfileDropdown_Select_Mutation } from './__generated__/UserProfileDropdown_Select_Mutation.graphql';
export const PROFILEMENU_QUERY = graphql`
  query UserProfileDropdown_Query($userId: Int!) {
    user(userId: $userId) @required(action: THROW) {
      name
      firstName
      imageUrl
      isDeleted
      ...UserProfileSidebar_UserProfile_Fragment
    }
    userSelectableCompanies {
      id
      companyId
      name
      imageUrl
    }
  }
`;
export const SELECT_COMPANY_MUTATION = graphql`
  mutation UserProfileDropdown_Select_Mutation($userCompanyInput: EditUserCompanyInput!) {
    editUserCompany(userCompanyInput: $userCompanyInput) {
      id
    }
  }
`;
export default function UserProfileDropdownContainer() {
  const [queryRef, loadQuery] = useQueryLoader<UserProfileDropdown_Query>(PROFILEMENU_QUERY);
  const user = useUser();
  useEffect(() => {
    loadQuery({
      userId: user.userId
    });
  }, [loadQuery, user.userId]);
  if (!queryRef) {
    // the suspense fallback does not work, so we have an explicit fallback here.
    return <UserProfilePlaceholder />;
  }
  return <Suspense fallback={<UserProfilePlaceholder />}>
      {queryRef && <UserProfileDropdown queryRef={queryRef} />}
    </Suspense>;
}
interface IDropdownContainerProps {
  queryRef: PreloadedQuery<UserProfileDropdown_Query>;
}
export function UserProfileDropdown({
  queryRef
}: IDropdownContainerProps): JSX.Element {
  const {
    userSelectableCompanies,
    user
  } = usePreloadedQuery<UserProfileDropdown_Query>(PROFILEMENU_QUERY, queryRef);
  const [selectCompany] = useMutation<UserProfileDropdown_Select_Mutation>(SELECT_COMPANY_MUTATION);
  const [userProfileSidebarOpen, setUserProfileSidebarOpen] = useState<boolean>(false);
  const router = useRouter();
  const logout = async () => {
    removeCookies();
    router.push(EppoDomainRoutes.LOGOUT);
  };
  const {
    userId,
    role,
    currentCompany
  } = useUser();
  const {
    addToast
  } = useToasts();
  const handleSelect = useCallback((companyId: number, name: string) => {
    selectCompany({
      variables: {
        userCompanyInput: {
          userId,
          companyId
        }
      },
      onCompleted: () => {
        const currentPathname = router.pathname;
        const basePath = currentPathname.split('/')[1];

        // we need a full page reload to fetch new user context and data.
        window.location.href = `/${basePath}`;
      },
      onError: () => {
        addToast(`Unable to select ${name}`, 'error');
      }
    });
  }, [addToast, router, selectCompany, userId]);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [focused, setFocused] = useState<number | null>(null);
  const showDropdown = () => setDropdownOpen(true);
  const hideDropdown = useCallback(() => {
    setFocused(null);
    setDropdownOpen(false);
  }, [setFocused, setDropdownOpen]);
  const elementId = domSafeElementId(`workspaces-menu`);
  const companyImg = userSelectableCompanies.find(c => c.companyId === currentCompany.companyId)?.imageUrl ?? null;
  const {
    collapsed
  } = useContext(NavCollapsedContext);
  const [visibleCompanies, setVisibleCompanies] = useState(userSelectableCompanies);
  const showSearch = userSelectableCompanies.length > 4;
  const handleChangeSearch = (event: SyntheticEvent) => {
    const text = ((event?.target as HTMLInputElement).value || '').toLowerCase();
    const companies = userSelectableCompanies.filter(company => company.name.toLowerCase().includes(text));
    setVisibleCompanies(companies);
  };
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      hideDropdown();
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (focused === null) {
        setFocused(0);
      } else {
        setFocused((focused + 1) % visibleCompanies.length);
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (focused === null) {
        setFocused(visibleCompanies.length - 1);
      } else {
        setFocused((focused - 1 + visibleCompanies.length) % visibleCompanies.length);
      }
    } else if (event.key === 'Enter') {
      if (focused === null) {
        return;
      }
      // only allow the select if this is NOT the currently selected company
      const focusedCompany = visibleCompanies[focused];
      if (focusedCompany.companyId !== currentCompany.companyId) {
        handleSelect(focusedCompany.companyId, focusedCompany.name);
      }
    }
  }, [hideDropdown, focused, setFocused, visibleCompanies, currentCompany.companyId, handleSelect]);
  useEffect(() => {
    if (focused === null) {
      return;
    }
    // scroll the focused item into view
    const focusedElement = document.getElementById(`company-${visibleCompanies[focused].companyId}`);
    focusedElement?.scrollIntoView({
      block: 'nearest'
    });
  }, [focused, visibleCompanies]);
  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [dropdownOpen, handleKeyDown]);
  return <ClickOutsideWrapper onClickOutside={hideDropdown}>
      <Tooltip text={currentCompany.name} disabled={collapsed} placement="right" tooltipProps={{
      offset: 28
    }} delayShow={600}>
        <UserProfileDropdownButton id={elementId} onClick={showDropdown} collapsed={collapsed} data-dropdown-open={dropdownOpen}>
          <ProfilePicture size="default" imageUrl={companyImg} text={currentCompany.name} />
          {!collapsed && <>
              <Text inline color="inherit">
                <AddEllipsis noOfLines={1} text={currentCompany.name} breakWord showTooltip={false} />
              </Text>
              <Icon name="Caret.Down" css={{
            marginLeft: 'auto'
          }} />
            </>}
        </UserProfileDropdownButton>
      </Tooltip>

      <Popover isOpen={dropdownOpen} target={elementId} placement="top-start" className="custom-popover no-margin-no-padding" modifiers={[preventOverflowModifier]} hideArrow>
        <PopoverBody>
          <MenuDropdownContainer>
            <MenuDropdownItem padding="8px 16px" gap="8px" alignItems="center" onClick={event => {
            event.stopPropagation();
            hideDropdown();
            setUserProfileSidebarOpen(true);
          }}>
              <ProfilePicture size="default" imageUrl={user.imageUrl} text={user.firstName} halfOpacity={user.isDeleted} />
              <div>
                <Text ellipsis>{user.name}</Text>
                <Text variant="body-small" color={colors.text.secondary}>
                  {role}
                </Text>
              </div>
            </MenuDropdownItem>

            <Hr color={colors.border.primary} />

            {showSearch && <div>
                <UserProfileDropdownSearch placeholder="Search" width="100%" onChange={handleChangeSearch} onClick={e => e.stopPropagation()} autoFocus />
              </div>}
            <MenuDropdownCompanies>
              {visibleCompanies.map((company, index) => <MenuDropdownItem key={company.companyId} id={`company-${company.companyId}`} onClick={() =>
            // only allow the click if this is NOT the currently selected company
            company.companyId !== currentCompany.companyId && handleSelect(company.companyId, company.name)} selected={company.companyId === currentCompany.companyId} focused={focused === index} padding="4px 16px 4px 20px">
                  <CompanyOption>
                    <ProfilePicture size="small" imageUrl={company.imageUrl} text={company.name} />
                    <TooltipWrapper placement="top-start" text={company.name} id={`company-tooltip-${company.companyId}`} tooltipStyle={{
                  width: 'auto',
                  minWidth: '65px'
                }} tooltipProps={{
                  delayShow: 400
                }}>
                      <Text ellipsis>{company.name}</Text>
                    </TooltipWrapper>
                    {company.companyId === currentCompany.companyId && <Icon name="Check mark.Small" color={colors.text.secondary} />}
                  </CompanyOption>
                </MenuDropdownItem>)}
            </MenuDropdownCompanies>

            <Hr color={colors.border.primary} />

            <MenuDropdownItem onClick={logout} padding="4px 16px">
              <Text>Log out</Text>
            </MenuDropdownItem>
          </MenuDropdownContainer>
        </PopoverBody>
      </Popover>
      {userProfileSidebarOpen && <UserProfileSidebar onClickOutside={() => setUserProfileSidebarOpen(false)} userProfileFragment={user} />}
    </ClickOutsideWrapper>;
}