import { useState } from 'react';
import { Resizable, ResizeCallback } from 're-resizable';
import ClickOutsideWrapper from 'components/click-outside-wrapper/ClickOutsideWrapper';
import { FlexBoxItem } from 'components/style-components/FlexBoxItem';
import { SidebarContainer, SidebarDiv } from './styled';
export interface ISidebar {
  children: React.ReactNode;
  width?: number;
  animate: boolean;
  onClickOutside: () => void;
  clickOutsideIgnoreAttribute?: string;
}
const DEFAULT_WIDTH = 376;
export function Sidebar({
  children,
  width = DEFAULT_WIDTH,
  animate,
  onClickOutside,
  clickOutsideIgnoreAttribute
}: ISidebar): JSX.Element {
  const [calculatedWidth, setCalculatedWidth] = useState<number>(width);
  const onResizeStopHandle: ResizeCallback = (_event, _direction, _element, {
    width: widthOffset
  }) => {
    setCalculatedWidth(calculatedWidth + widthOffset);
  };
  return <ClickOutsideWrapper onClickOutside={onClickOutside} ignoreAttribute={clickOutsideIgnoreAttribute}>
      <SidebarContainer onClick={e => e.stopPropagation()}>
        <FlexBoxItem flexGrow={1} />
        <Resizable size={{
        width: calculatedWidth,
        height: '100vh'
      }} onResizeStop={onResizeStopHandle} enable={{
        top: false,
        right: false,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }} style={{
        pointerEvents: 'all'
      }} minWidth={width}>
          <SidebarDiv animate={animate}>{children}</SidebarDiv>
        </Resizable>
      </SidebarContainer>
    </ClickOutsideWrapper>;
}