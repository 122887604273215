import { useEffect, useRef } from 'react';
export interface IClickOutsideWrapper {
  onClickOutside: (event?: MouseEvent) => void;
  children: React.ReactNode;
  ignoreAttribute?: string;
  captureEvent?: boolean;
}

/**
 * Wraps children with a div that triggers a callback for clicks outside the child.
 *
 * @param {(event?: MouseEvent) => void} onClickOutside
 * The callback to execute when clicking outside. Most common use case, is closing a modal or side-panel.
 * @param {ReactNode} children The React component to wrap.
 * @param {string} ignoreAttribute
 * onClickOutside will not be called for any element that is clicked with this as an attribute
 * e.g.
 * onClick={(event: SyntheticEvent) => {
 *   const htmlElement: HTMLElement = event.target as HTMLElement;
 *   htmlElement.setAttribute(IGNORE_CLICK_OUTSIDE_ATTRIBUTE, 'true');
 * }
 * or
 * <div {...{ [clickDivAttribute]: 'true' }} />
 * HOWEVER avoid this pattern unless absolutely necessary, so this is better:
 * onClick={(event: SyntheticEvent) => {
 *   event.stopPropagation();
 * }
 * @return {number} A div wrapping a child ReactNode.
 */
export default function ClickOutsideWrapper({
  onClickOutside,
  children,
  ignoreAttribute = '',
  captureEvent = false // Use event capturing (https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Building_blocks/Events#event_capture) instead of bubbling
}: IClickOutsideWrapper): JSX.Element {
  const containerRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      const isIgnored = (element: HTMLElement | null): boolean => {
        if (!element) return false;
        if (element.getAttribute(ignoreAttribute)) return true;
        return isIgnored(element.parentElement);
      };
      const ignoreOutsideClick = ignoreAttribute ? isIgnored((event.target as HTMLElement)) : false;
      if (!ignoreOutsideClick && containerRef.current && !(containerRef.current as HTMLElement).contains((event.target as Node))) {
        onClickOutside(event);
      }
    };
    document.addEventListener('click', handleClickOutside, captureEvent);
    return () => {
      document.removeEventListener('click', handleClickOutside, captureEvent);
    };
  }, [captureEvent, ignoreAttribute, onClickOutside]);
  return <div ref={containerRef}>{children}</div>;
}