import { Modifier } from 'reactstrap/node_modules/@popperjs/core';
import { ModifierPhases } from 'reactstrap/node_modules/@popperjs/core/lib/enums';

import { noop } from './function-helper';

export const preventOverflowModifier = {
  name: 'preventOverflow',
  options: {
    boundary: 'viewport',
  },
  enabled: true,
  phase: 'main' as ModifierPhases,
  fn: noop,
};

export const offsetModifier = ({
  name: 'offset',
  options: {
    offset: [0, 10],
  },
  enabled: true,
  phase: 'main' as ModifierPhases,
} as unknown) as Modifier<string, { offset: [number, number] }>;

export const disableOffsetModifier = {
  name: 'offset',
  options: {
    offset: [0, 10],
  },
  enabled: false,
  phase: 'main' as ModifierPhases,
  fn: noop,
};
