import {
  COLOR_CAT_1,
  COLOR_CAT_4,
  COLOR_GREY_200,
  COLOR_PINK_100,
  UNCATEGORIZED_COLOR,
} from 'constants/colors';

import { palette } from './palette';

/**
 * Functional color tokens.
 * Can be referenced directly in code in certain scenarios.
 *
 * Figma definition: {@link https://www.figma.com/design/q5V3oq0GCqLdnK6SjG2Ghl/%F0%9F%A7%B1-%5BOFFICIAL%5D-Foundations?node-id=18-7510&m=dev | Foundations file}
 *
 * NOTE: This list might be incomplete, add color tints as you need.
 */
export const functionalColors = {
  error: {
    '100': '#ffeff2',
    '200': '#ffdde3',
    '300': '#ffb6c4',
    '500': '#e23a59',
    '600': '#be092a',
  },
  warning: {
    '100': '#fdf5e4',
    '200': '#ffeac5',
    '300': '#ffd383',
    '500': '#ffa400',
    '600': '#de8f00',
  },
  success: {
    '100': '#e4f7f2',
    '200': '#c9f4ea',
    '300': '#9ee6d5',
    '500': '#21c19b',
    '600': '#0b9c79',
  },
  info: {
    '100': '#e7f1fa',
    '200': '#cde5fc',
    '500': '#3b8bd5',
    '600': '#256eb2',
  },
} as const;

/**
 * Semantic color tokens.
 *
 * These colors are supposed to be used in code 95% of the time. Directly translatable to Figma tokens: `Background/Primary` in Figma == `colors.background.primary`.
 *
 * Figma definition: {@link https://www.figma.com/design/q5V3oq0GCqLdnK6SjG2Ghl/%F0%9F%A7%B1-%5BOFFICIAL%5D-Foundations?node-id=3092-51909&m=dev | Foundations file}.
 */
export const colors = {
  text: {
    primary: palette.neutral['1000'],
    secondary: palette.neutral['800'],
    link: palette.peppo['600'],
    white: palette.white,

    disabledLight: palette.neutral['100'],
    disabledDark: palette.neutral['500'],

    error: functionalColors.error['600'],
    warning: functionalColors.warning['600'],
    success: functionalColors.success['600'],
    info: functionalColors.info['600'],
  },

  surface: {
    primary: palette.peppo['1000'],
    secondary: palette.peppo['600'],
    tertiary: palette.neutral['800'],
    white: palette.white,

    disabledLight: palette.neutral['100'],
    disabledDark: palette.neutral['500'],

    error: functionalColors.error['500'],
    warning: functionalColors.warning['500'],
    success: functionalColors.success['500'],
    info: functionalColors.info['500'],
  },

  background: {
    primary: palette.neutral['100'],
    secondary: palette.neutral['200'],
    purple: palette.peppo['100'],

    error: functionalColors.error['100'],
    success: functionalColors.success['100'],
    warning: functionalColors.warning['100'],
    info: functionalColors.info['100'],
  },

  border: {
    primary: palette.neutral['300'],
    secondary: palette.peppo['600'],

    error: functionalColors.error['500'],
    success: functionalColors.success['500'],
    warning: functionalColors.warning['500'],
    info: functionalColors.info['500'],
  },

  hover: {
    primary: palette.peppo['800'],
    secondary: palette.neutral['200'],
    tertiary: palette.peppo['100'],
  },

  variation: {
    background: [
      '#f5ecf4',
      '#fef1e6',
      '#e6f7fe',
      '#fdedf9',
      '#e9eaf6',
      '#f1f4ed',
      '#f8e8f1',
      '#e9f9f9',
      '#fef7e8',
      '#f1f3f8',
    ],
    objectFill: [
      '#9c3f96',
      '#f67206',
      '#07b0fb',
      '#ec4bc2',
      '#1f2caa',
      '#76934e',
      '#b81c73',
      '#25c4c5',
      '#f5b11e',
      '#718dbd',
    ],
  },
  named: {
    default: {
      fill: UNCATEGORIZED_COLOR,
      border: COLOR_CAT_1,
    },
    orange: {
      fill: '#FEF1E6',
      border: '#F67206',
    },
    blue: {
      fill: '#E6F7FE',
      border: '#07B0FB',
    },
    darkblue: {
      fill: '#E9EAF6',
      border: '#1F2CAA',
    },
    white: {
      fill: UNCATEGORIZED_COLOR,
      border: COLOR_GREY_200,
    },
    green: {
      fill: '#F1F4ED',
      border: '#76934E',
    },
    pink: {
      fill: COLOR_PINK_100,
      border: COLOR_CAT_4,
    },
    lime: {
      fill: palette.lime['300'],
      border: palette.lime['600'],
    },
    purple: {
      fill: UNCATEGORIZED_COLOR,
      border: COLOR_CAT_1,
    },
    peppo: {
      fill: palette.peppo['300'],
      border: palette.peppo['600'],
    },
  },
};

export const transparent = 'transparent';
export type TColor =
  | typeof colors.text[keyof typeof colors.text]
  | typeof colors.surface[keyof typeof colors.surface]
  | typeof colors.background[keyof typeof colors.background]
  | typeof colors.border[keyof typeof colors.border]
  | typeof colors.hover[keyof typeof colors.hover]
  | typeof transparent;
