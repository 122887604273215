import { SPACE_M, SPACE_S } from 'constants/sizes';
import { FONT_SIZE_SMALL } from 'constants/typography';

import styled from '@emotion/styled';

import { SearchInput } from 'components/inputs/styled';
import { FlexBox } from 'components/style-components/FlexBox';

import { colors } from 'theme/colors';

import searchIcon from './search.svg';

export const UserProfilePlaceholder = styled.div({
  width: '100%',
  minHeight: '32px',
});

export const UserProfileDropdownButton = styled.button<{ collapsed: boolean }>(({ collapsed }) => ({
  width: '100%',
  minHeight: '32px',
  padding: SPACE_S,
  display: 'flex',
  alignItems: 'center',
  gap: SPACE_M,
  backgroundColor: 'transparent',
  color: colors.text.white,
  borderRadius: '8px',
  border: `solid 1px ${colors.border.primary}`,

  '> div:first-of-type': {
    width: '24px',
    height: '24px',
    flex: '0 0 24px',
    borderRadius: '12px',
    outline: `solid 1px transparent`,
  },

  ...(collapsed
    ? {
        borderColor: 'transparent',
      }
    : {
        '&:hover, &[data-dropdown-open=true]': {
          backgroundColor: colors.surface.white,
          color: colors.text.primary,

          '& > div:first-of-type': {
            outlineColor: colors.border.primary,
          },
        },
      }),
}));

export const MenuDropdownContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: SPACE_M,
  width: '220px',
  maxHeight: '320px',
  padding: '8px 4px 16px',
  overflowY: 'scroll',
  overflowX: 'hidden',
});

export const MenuDropdownCompanies = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  overflowY: 'scroll',
});

export const ProfileName = styled.div({
  color: colors.text.primary,
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
});

export const ProfileRole = styled.div({
  fontSize: FONT_SIZE_SMALL,
  color: colors.text.secondary,
});

export const ProfileEmail = ProfileRole;

export const MenuDropdownItem = styled(FlexBox)<{
  selected?: boolean;
  focused?: boolean;
}>(({ selected = false, focused = false }) => ({
  display: 'flex',
  alignItems: 'center',

  fontSize: '14px',

  color: colors.text.primary,
  backgroundColor: focused ? colors.hover.secondary : 'transparent',
  borderRadius: '8px',

  '&:hover, &:focus': {
    cursor: selected ? 'default' : 'pointer',
    background: selected ? 'transparent' : colors.hover.secondary,
  },
}));

export const CompanyOption = styled.div({
  width: '100%',
  display: 'flex',
  gap: '8px',
  justifyContent: 'space-between',
  alignItems: 'center',

  '> div:first-of-type': {
    flex: '0 0 24px',
  },

  '> div:nth-of-type(2)': {
    flex: '1 1 auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    '> div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  '> div:nth-of-type(3)': {
    marginLeft: '8px',
    flex: '0 0 24px',
  },
});

export const TeamSimpleTableHead = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

export const TeamSimpleTableFirstCell = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
});

export const TeamSimpleTableCell = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const UserProfileDropdownSearch = styled(SearchInput)({
  paddingLeft: '40px',
  fontFamily: 'Cofo Sans, sans-serif',
  fontSize: '14px',
  backgroundColor: 'transparent',
  backgroundImage: `url(${searchIcon})`,
  backgroundSize: '24px 24px',
  backgroundPosition: '8px 4px',
  border: `1px solid ${colors.border.primary}`,
  borderRadius: '8px',
});
