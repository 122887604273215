import { sidebarTransition } from 'constants/animations';

import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import Link from 'next/link';
import { boxShadow, colors } from 'theme';

import { zIndexFixed, zIndexTooltip } from 'styles/z-index';

const COLOR_BACKGROUND_SECONDARY_TRANSPARENT = 'rgba(242, 240, 237, 0.16)';

export const sidebarExpandedWidth = 200;
export const sidebarCollapsedWidth = 64;

export const SidebarContainer = styled.aside<{ collapsed: boolean }>(({ collapsed }) => ({
  height: '100vh',
  flex: `0 0 ${collapsed ? sidebarCollapsedWidth : sidebarExpandedWidth}px`,
  width: `${collapsed ? sidebarCollapsedWidth : sidebarExpandedWidth}px`,
  transition: sidebarTransition,
  position: 'fixed',
  zIndex: zIndexFixed,
}));

export const SidebarWrapper = styled.nav({
  minHeight: '100vh',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 16px 24px',
  color: colors.text.white,
  backgroundColor: colors.surface.primary,
});

export const SidebarToggleButton = styled.button({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  padding: 0,
  backgroundColor: 'transparent',
  color: 'inherit',
});

export const SidebarOptionStyled = styled(Link, {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})<{
  isActive?: boolean;
  collapsed?: boolean;
  color?: string;
  backgroundColor?: string;
}>(({ isActive, collapsed = false, color, backgroundColor }) => ({
  display: 'flex',
  padding: '4px',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '8px',
  color: color ?? (isActive ? colors.text.primary : colors.text.white),
  fontWeight: isActive ? '500' : undefined,
  backgroundColor: backgroundColor ?? (isActive ? colors.background.secondary : undefined),

  p: {
    display: collapsed ? 'none' : 'block',
  },

  ':hover': {
    width: `${sidebarExpandedWidth - 32}px`,
    zIndex: collapsed ? zIndexTooltip + 1 : undefined,
    boxShadow: collapsed ? boxShadow.default : undefined,
    ...(isActive
      ? {
          color: colors.text.primary,
        }
      : {
          color: collapsed ? colors.text.primary : colors.text.white,
          backgroundColor: collapsed
            ? colors.surface.white
            : COLOR_BACKGROUND_SECONDARY_TRANSPARENT,
        }),

    p: {
      display: 'block',
    },
  },
}));
