import { Component, ErrorInfo, ReactNode } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/nextjs';
type FallbackRenderer = (error: Error) => React.ReactNode;
interface IErrorBoundaryState {
  hasError: boolean;
}
interface IErrorBoundaryProps {
  fallback?: ReactNode | FallbackRenderer;
  children: ReactNode;
}

/**
 * @see https://github.com/DataDog/rum-react-integration-examples/blob/master/src/ErrorBoundary/ErrorBoundary.ts
 * ErrorBoundary component sends enriched errors to datadog RUM.
 *
 * Note: ErrorBoundary components will bubble up errors in dev mode, but not in prod. See https://github.com/facebook/react/issues/10474
 */
export class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true
    };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    datadogRum.addError(error, errorInfo);
    Sentry.captureException(error);
  }
  render() {
    const {
      fallback = null,
      children
    } = this.props;
    if (this.state.hasError) {
      return fallback;
    }
    return children;
  }
}