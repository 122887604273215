import styled from '@emotion/styled';

/**
 * Primitive UI component that resets default browser button styles.
 *
 * Useful in cases when creating a new button from scratch, or when we want to make something clickable that's not necessarily a button or a link.
 */
export const UnstyledButton = styled.button({
  padding: 0,
  margin: 0,
  backgroundColor: 'transparent',
  border: 'none',
  color: 'inherit'
});