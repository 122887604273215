import { useContext } from 'react';
import { RbacPermissionsContext } from 'components/RbacPermissionsProvider';

// to add a new permission:
// 1. add an enum constant here
// 2. add the developer-friendly key to the allPermissions type
// 3. add logic for the key in generatePermissions

export enum ERawPermission {
  EXPERIMENT_CREATE = 'experiment.create',
  EXPERIMENT_UPDATE = 'experiment.update',
  EXPERIMENT_DELETE = 'experiment.delete',
  DEFINITION_CREATE = 'definitions.create',
  DEFINITION_UPDATE = 'definitions.update',
  DEFINITION_DELETE = 'definitions.delete',
  METRIC_CREATE = 'metric.create',
  METRIC_UPDATE = 'metric.update',
  METRIC_DELETE = 'metric.delete',
  FEATUREFLAG_CREATE = 'feature_flag.create',
  FEATUREFLAG_UPDATE = 'feature_flag.update',
  ADMIN_READ = 'admin.read',
  COMPANYUSERS_READ = 'company_users.read',
  COMPANYUSERS_CREATE = 'company_users.create',
  COMPANYUSERS_UPDATE = 'company_users.update',
  COMPANYUSERS_DELETE = 'company_users.delete',
  SLACK_CREATE = 'slack_notifications.create',
  SLACK_UPDATE = 'slack_notifications.update',
  SLACK_DELETE = 'slack_notifications.delete',
  APIKEYS_READ = 'api_keys.read',
  ENVIRONMENTS_CREATE = 'environment.create',
  ENVIRONMENTS_UPDATE = 'environment.update',
  ENVIRONMENTS_DELETE = 'environment.delete',
  SDKKEYS_CREATE = 'sdk_key.create',
  SDKKEYS_UPDATE = 'sdk_key.update',
  SDKKEYS_DELETE = 'sdk_key.delete',
  HOLDOUT_CREATE = 'holdout.create',
  HOLDOUT_UPDATE = 'holdout.update',
  HOLDOUT_DELETE = 'holdout.delete',
  TEAM_CREATE = 'team.create',
  TEAM_UPDATE = 'team.update',
  TEAM_DELETE = 'team.delete',
  FEATURE_FLAG_SUGGESTION_CREATE = 'feature_flag_suggestion.create',
  FEATURE_FLAG_SUGGESTION_UPDATE = 'feature_flag_suggestion.update',
  FEATURE_FLAG_SUGGESTION_DELETE = 'feature_flag_suggestion.delete',
  FEATURE_FLAG_SUGGESTION_REVIEW = 'feature_flag_suggestion.review',
  ENTRYPOINT_CREATE = 'entry_points.create',
  ENTRYPOINT_UPDATE = 'entry_points.update',
  ENTRYPOINT_DELETE = 'entry_points.delete',
  DATABASE_CONNECTION_CREATE = 'database_connection_config.create',
  DATABASE_CONNECTION_UPDATE = 'database_connection_config.update',
  DATABASE_CONNECTION_DELETE = 'database_connection_config.delete',
  METRIC_COLLECTION_CREATE = 'metric_collection.create',
  METRIC_COLLECTION_UPDATE = 'metric_collection.update',
  METRIC_COLLECTION_DELETE = 'metric_collection.delete',
  SEGMENT_CREATE = 'segment.create',
  SEGMENT_UPDATE = 'segment.update',
  SEGMENT_DELETE = 'segment.delete',
  SAMPLE_SIZE_CALCULATION_CREATE = 'sample_size_calculation.create',
  SAMPLE_SIZE_CALCULATION_UPDATE = 'sample_size_calculation.update',
  SAMPLE_SIZE_CALCULATION_DELETE = 'sample_size_calculation.delete',
  METRIC_SYNC_READ = 'metric_sync.read',
  METRIC_SYNC_CREATE = 'metric_sync.create',
  METRIC_SYNC_UPDATE = 'metric_sync.update',
  METRIC_SYNC_DELETE = 'metric_sync.delete',
  WEBHOOK_READ = 'webhooks.read',
  ANALYSIS_PLAN_READ = 'analysis_plan.read',
  DATABASE_CONNECTION_READ = 'database_connection_config.read',
  SCHEDULES_READ = 'schedules.read',
  WORKFLOW_READ = 'workflow.read',
}
export default function useRbacPermissions() {
  const {
    rbacPermissions
  } = useContext(RbacPermissionsContext);
  return rbacPermissions;
}
type allPermissions = 'canCreateExperiment' | 'canUpdateExperiment' | 'canDeleteExperiment' | 'canCreateDefinition' | 'canUpdateDefinition' | 'canDeleteDefinition' | 'canCreateMetric' | 'canUpdateMetric' | 'canDeleteMetric' | 'canCreateFeatureFlag' | 'canUpdateFeatureFlag' | 'canCreateSlack' | 'canUpdateSlack' | 'canDeleteSlack' | 'canReadAdmin' | 'canReadApiKeys' | 'canReadCompanyUsers' | 'canCreateCompanyUsers' | 'canUpdateCompanyUsers' | 'canDeleteCompanyUsers' | 'canCreateEnvironment' | 'canUpdateEnvironment' | 'canDeleteEnvironment' | 'canCreateSdkKey' | 'canUpdateSdkKey' | 'canDeleteSdkKey' | 'canCreateHoldout' | 'canUpdateHoldout' | 'canDeleteHoldout' | 'canCreateTeam' | 'canUpdateTeam' | 'canDeleteTeam' | 'canCreateFeatureFlagSuggestion' | 'canUpdateFeatureFlagSuggestion' | 'canDeleteFeatureFlagSuggestion' | 'canReviewFeatureFlagSuggestion' | 'canCreateEntrypoint' | 'canUpdateEntrypoint' | 'canDeleteEntrypoint' | 'canCreateDatabaseConnection' | 'canUpdateDatabaseConnection' | 'canDeleteDatabaseConnection' | 'canCreateMetricCollection' | 'canUpdateMetricCollection' | 'canDeleteMetricCollection' | 'canCreateSegment' | 'canUpdateSegment' | 'canDeleteSegment' | 'canCreateSampleSizeCalculation' | 'canUpdateSampleSizeCalculation' | 'canDeleteSampleSizeCalculation' | 'canReadMetricSync' | 'canCreateMetricSync' | 'canUpdateMetricSync' | 'canDeleteMetricSync' | 'canReadWebhooks' | 'canReadAnalysisPlan' | 'canReadDatabaseConnection' | 'canReadSchedules' | 'canReadWorkflow';
export type RbacPermissionsRecord = Record<allPermissions, boolean>;
export function generateRbacPermissions(permissions: readonly string[]): RbacPermissionsRecord {
  return {
    canCreateExperiment: canDo(permissions, ERawPermission.EXPERIMENT_CREATE),
    canUpdateExperiment: canDo(permissions, ERawPermission.EXPERIMENT_UPDATE),
    canDeleteExperiment: canDo(permissions, ERawPermission.EXPERIMENT_DELETE),
    canCreateDefinition: canDo(permissions, ERawPermission.DEFINITION_CREATE),
    canUpdateDefinition: canDo(permissions, ERawPermission.DEFINITION_UPDATE),
    canDeleteDefinition: canDo(permissions, ERawPermission.DEFINITION_DELETE),
    canCreateMetric: canDo(permissions, ERawPermission.METRIC_CREATE),
    canUpdateMetric: canDo(permissions, ERawPermission.METRIC_UPDATE),
    canDeleteMetric: canDo(permissions, ERawPermission.METRIC_DELETE),
    canCreateFeatureFlag: canDo(permissions, ERawPermission.FEATUREFLAG_CREATE),
    canUpdateFeatureFlag: canDo(permissions, ERawPermission.FEATUREFLAG_UPDATE),
    canCreateSlack: canDo(permissions, ERawPermission.SLACK_CREATE),
    canUpdateSlack: canDo(permissions, ERawPermission.SLACK_UPDATE),
    canDeleteSlack: canDo(permissions, ERawPermission.SLACK_DELETE),
    canReadAdmin: canDo(permissions, ERawPermission.ADMIN_READ),
    canReadApiKeys: canDo(permissions, ERawPermission.APIKEYS_READ),
    canCreateEnvironment: canDo(permissions, ERawPermission.ENVIRONMENTS_CREATE),
    canUpdateEnvironment: canDo(permissions, ERawPermission.ENVIRONMENTS_UPDATE),
    canDeleteEnvironment: canDo(permissions, ERawPermission.ENVIRONMENTS_DELETE),
    canCreateSdkKey: canDo(permissions, ERawPermission.SDKKEYS_CREATE),
    canUpdateSdkKey: canDo(permissions, ERawPermission.SDKKEYS_UPDATE),
    canDeleteSdkKey: canDo(permissions, ERawPermission.SDKKEYS_DELETE),
    canReadCompanyUsers: canDo(permissions, ERawPermission.COMPANYUSERS_READ),
    canCreateCompanyUsers: canDo(permissions, ERawPermission.COMPANYUSERS_CREATE),
    canUpdateCompanyUsers: canDo(permissions, ERawPermission.COMPANYUSERS_UPDATE),
    canDeleteCompanyUsers: canDo(permissions, ERawPermission.COMPANYUSERS_DELETE),
    canCreateHoldout: canDo(permissions, ERawPermission.HOLDOUT_CREATE),
    canUpdateHoldout: canDo(permissions, ERawPermission.HOLDOUT_UPDATE),
    canDeleteHoldout: canDo(permissions, ERawPermission.HOLDOUT_DELETE),
    canCreateTeam: canDo(permissions, ERawPermission.TEAM_CREATE),
    canUpdateTeam: canDo(permissions, ERawPermission.TEAM_UPDATE),
    canDeleteTeam: canDo(permissions, ERawPermission.TEAM_DELETE),
    canCreateFeatureFlagSuggestion: canDo(permissions, ERawPermission.FEATURE_FLAG_SUGGESTION_CREATE),
    canUpdateFeatureFlagSuggestion: canDo(permissions, ERawPermission.FEATURE_FLAG_SUGGESTION_UPDATE),
    canDeleteFeatureFlagSuggestion: canDo(permissions, ERawPermission.FEATURE_FLAG_SUGGESTION_DELETE),
    canReviewFeatureFlagSuggestion: canDo(permissions, ERawPermission.FEATURE_FLAG_SUGGESTION_REVIEW),
    canCreateEntrypoint: canDo(permissions, ERawPermission.ENTRYPOINT_CREATE),
    canUpdateEntrypoint: canDo(permissions, ERawPermission.ENTRYPOINT_UPDATE),
    canDeleteEntrypoint: canDo(permissions, ERawPermission.ENTRYPOINT_DELETE),
    canCreateDatabaseConnection: canDo(permissions, ERawPermission.DATABASE_CONNECTION_CREATE),
    canUpdateDatabaseConnection: canDo(permissions, ERawPermission.DATABASE_CONNECTION_UPDATE),
    canDeleteDatabaseConnection: canDo(permissions, ERawPermission.DATABASE_CONNECTION_DELETE),
    canCreateMetricCollection: canDo(permissions, ERawPermission.METRIC_COLLECTION_CREATE),
    canUpdateMetricCollection: canDo(permissions, ERawPermission.METRIC_COLLECTION_UPDATE),
    canDeleteMetricCollection: canDo(permissions, ERawPermission.METRIC_COLLECTION_DELETE),
    canCreateSegment: canDo(permissions, ERawPermission.SEGMENT_CREATE),
    canUpdateSegment: canDo(permissions, ERawPermission.SEGMENT_UPDATE),
    canDeleteSegment: canDo(permissions, ERawPermission.SEGMENT_DELETE),
    canCreateSampleSizeCalculation: canDo(permissions, ERawPermission.SAMPLE_SIZE_CALCULATION_CREATE),
    canUpdateSampleSizeCalculation: canDo(permissions, ERawPermission.SAMPLE_SIZE_CALCULATION_UPDATE),
    canDeleteSampleSizeCalculation: canDo(permissions, ERawPermission.SAMPLE_SIZE_CALCULATION_DELETE),
    canReadMetricSync: canDo(permissions, ERawPermission.METRIC_SYNC_READ),
    canCreateMetricSync: canDo(permissions, ERawPermission.METRIC_SYNC_CREATE),
    canUpdateMetricSync: canDo(permissions, ERawPermission.METRIC_SYNC_UPDATE),
    canDeleteMetricSync: canDo(permissions, ERawPermission.METRIC_SYNC_DELETE),
    canReadWebhooks: canDo(permissions, ERawPermission.WEBHOOK_READ),
    canReadAnalysisPlan: canDo(permissions, ERawPermission.ANALYSIS_PLAN_READ),
    canReadDatabaseConnection: canDo(permissions, ERawPermission.DATABASE_CONNECTION_READ),
    canReadSchedules: canDo(permissions, ERawPermission.SCHEDULES_READ),
    canReadWorkflow: canDo(permissions, ERawPermission.WORKFLOW_READ)
  };
}
function canDo(permissions: readonly string[], permission: string) {
  return permissions.some(allowed => allowed === permission);
}