export const spacing = {
  xs: '4px',
  sm: '8px',
  md: '12px',
  lg: '16px',
  xl: '24px',
  xxl: '32px',
  xxxl: '40px',
  xxxxl: '48px',
  xxxxxl: '56px',
  xxxxxxl: '64px',
  xxxxxxxl: '72px',
} as const;
