import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import EppoLogo from 'components/logo/EppoLogo';
import { FlexBox } from 'components/style-components/FlexBox';
import Icon from 'components/ui/icon/Icon';
import UserProfileDropdown from 'components/user-profile-dropdown/UserProfileDropdown';
import { NavCollapsedContext } from 'hooks/useNavCollapsed';
import SidebarOption from './SidebarOption';
import { SidebarWrapper, SidebarToggleButton } from './styled';
const baseRoute = (pathname: string) => `/${pathname.split('/')[1] || ''}`;
interface ISidebarProp {
  options: ISideMenuOption[];
  bottomOptions?: ISideMenuOption[];
  updatesHighlightStrategy?: string;
}
export interface ISideMenuOption {
  name: string;
  url: string;
  isHidden?: boolean;
  handleSelect?: (event: SyntheticEvent) => void;
  forceNewTab?: boolean;
}
export default function Sidebar({
  options,
  bottomOptions,
  updatesHighlightStrategy
}: ISidebarProp) {
  const router = useRouter();
  const [selectedOption, setSelectedOption] = useState<string>('');
  useEffect(() => {
    const afterChange = () => {
      setSelectedOption(baseRoute(router.pathname));
    };
    router.events.on('routeChangeComplete', afterChange);
    return () => {
      router.events.off('routeChangeComplete', afterChange);
    };
  }, [router, setSelectedOption]);
  const isOptionActive = (url: string): boolean => {
    if (!url) {
      return false;
    }
    if (selectedOption) {
      return url.toLowerCase().localeCompare(selectedOption.toLowerCase()) === 0;
    }
    const selectedOptionName = baseRoute(router.pathname);
    return url.toLowerCase().localeCompare(selectedOptionName.toLowerCase()) === 0;
  };
  const {
    collapsed,
    toggleCollapsed
  } = useContext(NavCollapsedContext);
  return <SidebarWrapper>
      <FlexBox justifyContent={collapsed ? 'center' : 'space-between'} alignItems="flex-start" padding="4px 0 0" height="32px">
        {!collapsed && <FlexBox padding="0 0 0 4px">
            <EppoLogo color="light" height="28px" />
          </FlexBox>}
        <SidebarToggleButton onClick={toggleCollapsed}>
          <Icon name="First page arrow.first_page" style={{
          transform: collapsed ? 'rotate(180deg)' : undefined
        }} />
        </SidebarToggleButton>
      </FlexBox>

      <FlexBox flex={1} flexDirection="column" gap="24px" margin="40px 0 0">
        {options.filter(({
        isHidden
      }) => !isHidden).map(({
        name,
        url
      }: ISideMenuOption) => <SidebarOption key={url} url={url} name={name} handleSelect={() => setSelectedOption(url)} isActive={isOptionActive(url)} />)}
      </FlexBox>

      <UserProfileDropdown />

      {!!bottomOptions?.length && <FlexBox flexDirection="column" gap="16px" margin="16px 0 0">
          {bottomOptions.map(({
        name,
        url,
        handleSelect,
        forceNewTab
      }) => {
        let highlightStrategy = undefined;
        if (name.toLowerCase() === 'updates' && updatesHighlightStrategy) {
          highlightStrategy = updatesHighlightStrategy;
        }
        return <SidebarOption key={url} url={url} name={name} handleSelect={handleSelect} forceNewTab={forceNewTab} highlightStrategy={highlightStrategy} />;
      })}
        </FlexBox>}
    </SidebarWrapper>;
}