import { SyntheticEvent, useContext } from 'react';
import { COLOR_SUCCESS } from 'constants/colors';
import Icon from 'components/ui/icon/Icon';
import { IconName } from 'components/ui/icon/icon-map';
import Text from 'components/ui/typography/Text';
import { NavCollapsedContext } from 'hooks/useNavCollapsed';
import { SidebarOptionStyled } from './styled';
const icons: Record<string, IconName> = {
  Admin: 'Tune.Vertical',
  Analysis: 'Experiment',
  Configuration: 'Feature flag',
  Definitions: 'Definitions',
  Docs: 'Help.Unfilled',
  'Getting Started': 'List',
  Insights: 'Idea',
  Metrics: 'Metric',
  Teams: 'Team',
  Warehouse: 'Database.Unfilled',
  Updates: 'Speaker'
};
interface ISidebarOption {
  handleSelect?: (event: SyntheticEvent) => void;
  isActive?: boolean;
  name: string;
  url: string;
  forceNewTab?: boolean;
  highlightStrategy?: string;
}
const SidebarOption = ({
  handleSelect,
  isActive,
  name,
  url,
  forceNewTab,
  highlightStrategy
}: ISidebarOption) => {
  const {
    collapsed
  } = useContext(NavCollapsedContext);
  const newTab = !handleSelect || forceNewTab;

  // Temporarily, as part of dogfooding a multi-armed bandit, we have a few different ways to
  // "highlight" that there are new updates. Based on highlightStrategy, we will decorate the name
  // of this sidebar option.
  const emojiDecorator = highlightStrategy === 'emojiSparkle' ? '✨' : null;
  const backgroundColor = highlightStrategy === 'greenBackground' ? COLOR_SUCCESS : undefined;
  const color = highlightStrategy === 'greenText' ? COLOR_SUCCESS : undefined;
  const dot = highlightStrategy === 'greenDot' ? <span style={{
    color: COLOR_SUCCESS
  }}> ⬤</span> : null;
  return <SidebarOptionStyled href={url} onClick={handleSelect ?? undefined} target={newTab ? '_blank' : undefined} collapsed={collapsed} isActive={isActive} backgroundColor={backgroundColor} color={color}>
      {icons[name] && <Icon name={icons[name]} />}
      <Text color="inherit" bold={isActive} ellipsis>
        {emojiDecorator}
        {name}
        {dot}
        {emojiDecorator}
      </Text>
    </SidebarOptionStyled>;
};
export default SidebarOption;